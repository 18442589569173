/*--------*/
/* GLOBAL */
/*--------*/
:root {
	--white: #e7ecef;
	--grey: #8b8c89;
	--blue: #6096ba;
	--blue-light: #a3cef1;
	--blue-dark: #274c77;
	--navbar-bg: #274c77;
	--navbar-bg-sticky: #274c77e6;
	--background:#e7ecef;
	--icon-color: #6096ba;
	--icon-background: #e7ecef;
	--image-gradient: linear-gradient(to bottom left, rgba(3, 38, 65, 0.678), rgba(12, 10, 22, 0.863));	
}

[data-theme='dark'] {	
	--white: #e7ecef;
	--grey: #274c77;
	--blue: #8aaaff;
	--blue-light: #5c96d8;
	--blue-dark: #BBE1FA;
	--navbar-bg: #071e38;
	--navbar-bg-sticky: ##071e38af;
	--background:#042142;
	--icon-color:#a3cef1;
	--icon-background: #042142;
	--image-gradient: linear-gradient(to bottom left, rgb(0 0 0 / 68%), rgb(2 0 10 / 86%));
  }

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

body {
	margin: 0;
	font-family: "Montserrat", serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;	
	/* background: linear-gradient(to right, #e7ecef, #e7ecef);  */
	background: var(--background) !important; 
	background-color: var(--background) !important;
	
}


.myblue {
	color: var(--imp-text-color) !important;
}


.App {
	text-align: center;
  }

#spin-loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;	
	background-image: url(./Assets/double-ring.svg);
	background-repeat: no-repeat;
	background-position: center;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background-color: var(--background);
	background-image: url(./Assets/double-ring.svg);
	background-repeat: no-repeat;
	background-position: center;
}

#preloader-none {
	opacity: 0;
}

#no-scroll {
	overflow: hidden;
	height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
	width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #131747;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(40, 58, 107, 1);
	border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(70, 95, 165, 0.959);
	border-radius: 12px;
}

/* ------ */
/* Navbar */
/* ------ */
.sticky {
	background-color: var(--navbar-bg-sticky) !important;	
	transition: all 0.3s ease-out 0s !important;
	box-shadow: 0px 10px 10px 0px rgba(5, 9, 29, 0.171) !important;
	backdrop-filter: blur(15px) !important;
}

.navbar {
	background-color: var(--navbar-bg);	
	position: fixed !important;
	transition: all 0.3s ease-out 0s !important;
	padding: 0.1rem 2rem !important;
	font-size: 1.2rem !important;
	text-align: center;
}

.navbar-toggler {
	padding: 0.25rem 1.5rem !important;
	position: relative !important;
	background-color: transparent !important;
	border-color: transparent !important;
}

.navbar-toggler span {
	display: block !important;
	background-color: var(--blue) !important;
	height: 4px !important;
	width: 27px !important;
	margin-top: 5px !important;
	margin-bottom: 5px !important;
	transform: rotate(0deg) !important;
	left: 0 !important;
	opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
	outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
	transition: transform 0.35s ease-in-out !important;
	transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
	position: absolute !important;
	left: 12px !important;
	top: 10px !important;
	transform: rotate(135deg) !important;
	opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
	height: 12px !important;
	visibility: hidden !important;
	background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
	position: absolute !important;
	left: 12px !important;
	top: 10px !important;
	transform: rotate(-135deg) !important;
	opacity: 0.9 !important;
}

@media (max-width: 992px) {
	.fixed-top {
		top: auto !important;
		position: fixed;
		bottom: 0 !important;
		right: 0;
		left: 0;
		z-index: 1030;
	}
}


@media (max-width: 767px) {
	.navbar {
		padding: 0.5rem 0.5rem !important;
		font-size: 0.8rem !important;
		/* background-color: #181a27 !important; */
	}

	.navbar-nav .nav-item a::after {
		display: none !important;
	}

	.navbar-nav {
		flex-direction: initial !important;
	}

	.navbar-nav .nav-item {
		margin-left: 5px !important;
	}

	.navbar-expand-md .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}

	.navbar-toggler {
		display: none !important;
	}
}

.navbar-collapse {
	flex-grow: 0 !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

.navbar-brand {
	color: rgb(250, 250, 250) !important;
}

.logo {
	height: 1.4em !important;
	width: 2.5em !important;
}

.navbar-nav .nav-link {
	color: var(--white) !important;
	padding-right: 2rem !important;
	padding-left: 1rem !important;
}

.nav-link {
	padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
	.nav-link {
		padding: 0.2rem 0.2rem !important;
	}

	.navbar-nav .nav-item a::after {
		content: "";
		position: relative !important;
		display: block !important;
		height: 3px;
		width: 0;
		border-radius: 16px;
		background: var(--blue);
		bottom: 1px;
		left: 0;
		z-index: -1;
		transition: all 0.3s ease-out 0s;
	}

	.navbar-nav .nav-item a:hover::after {
		width: 100%;
	}
}

@media (max-width: 430px) {
	.navbar-nav .nav-link {
		padding-right: 0.7rem !important;
		padding-left: 0.7rem !important;

	}
}

.navbar-nav .nav-item {
	position: relative;
	margin-left: 20px;
}

.navbar-nav .nav-item a {
	font-weight: 400;
	transition: all 0.3s ease-out 0s;
	position: relative;
	z-index: 1;
}

.navbar-nav .nav-item a::after {
	content: "";
	position: relative;
	display: block;
	height: 3px;
	width: 0;
	border-radius: 16px;
	background: var(--blue);
	bottom: 1px;
	left: 0;
	z-index: -1;
	transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
	width: 100%;
}


a.m-active.nav-link::after {
	content: "";
	position: relative;
	display: block;
	height: 3px;
	width: 0;
	border-radius: 16px;
	background: var(--blue);
	bottom: 1px;
	left: 0;
	z-index: -1;
	transition: all 0.3s ease-out 0s;
	width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
	animation-name: wave-animation;
	/* Refers to the name of your @keyframes element below */
	animation-duration: 2.1s;
	/* Change to speed up or slow down */
	animation-iteration-count: infinite;
	/* Never stop waving :) */
	transform-origin: 70% 70%;
	/* Pivot around the bottom-left palm */
	display: inline-block;
}


#tsparticles {
	position: fixed !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	width: 100%;
	height: 100%;
}

.home-header {
	/* padding-top: 80px !important; */
	padding-top: 25vh !important;
}

.home-section {
	position: relative;
	height: 100vh;
	/* z-index: -1; */
	background-image: var(--image-gradient), url(./Assets/bg-min.jpg);
	background-position: top center;
	background-repeat: no-repeat;
	padding-bottom: 30px !important;
	padding-top: 30px !important;
	background-size: cover;
}

.home-content {
	padding: 9rem 0 2rem !important;
	color: whitesmoke;
}

@media (max-width: 1024px) {
	.home-content {
		padding: 6rem 0 2rem !important;
		color: whitesmoke;
	}
}

@media (max-width: 916px) {
	.home-content {
		padding: 2rem 0 2rem !important;
		color: whitesmoke;
	}
}

@media (max-width: 768px) {
	.home-content {
		padding: 4rem 0 2rem !important;
		color: whitesmoke;
	}
}

@media (max-width: 480px) {
	.home-content {
		padding: 0.5rem 0 2rem !important;
		color: whitesmoke;
	}
}

.heading {
	font-size: 2.4em !important;
	/* padding-left: 50px !important; */
}

.heading-name {
	font-size: 2.5em !important;
	/* text-align: center; */
	/* padding-left: 45px !important; */
}

.main-name {
	color: var(--blue);
}

.Typewriter__wrapper {
	font-size: 1.2em !important;
	color: var(--blue-light) !important;
	font-weight: 600 !important;
}

.Typewriter__cursor {
	font-size: 1.4em !important;
	color: var(--blue-light) !important;
}

@media (max-width: 767px) {
	.Typewriter__wrapper {
		font-size: 1em !important;
		font-weight: 500 !important;
		position: relative !important;
	}

	.Typewriter__cursor {
		display: none !important;
	}
}

.myAvatar {
	justify-content: center !important;
	padding-top: 9em !important;
	border-radius: 50%;
}

.img-avatar {
	/* position: relative; */
	border-radius: 50%;
	height: 240px;
	width: 240px;
	padding: 0;
	margin: 0;
	border: 2px solid var(--blue);
}

@media (max-width: 767px) {
	.myAvatar {
		padding-top: 2em !important;
		padding-bottom: 2em !important;
	}
}

.home-about-section {
	position: relative;
	padding-bottom: 20px !important;
	padding-top: 20px !important;
	background-color: var(--background) !important;
}

@media (max-width: 992px) {
	.home-about-section {
		padding-bottom: 50px !important;
	}
}

.home-about-description {
	/* color: white !important; */
	color: var(--blue-dark) !important;
	padding-top: 50px !important;
	padding-bottom: 20px !important;
	text-align: center;
}

.home-about-body {
	padding-top: 50px;
	font-size: 1.2em !important;
	text-align: left;
}

.home-about-social {
	text-align: center !important;
	padding-top: 25px;
	color: var(--blue-dark) !important;
}

.home-about-social-links {
	justify-content: center !important;
	padding-top: 15px !important;
	display: inline-block !important;
	position: relative !important;
	padding-inline-start: 0 !important;
}

.home-social-icons {
	position: relative !important;
	display: inline-block !important;
	width: 40px !important;
	height: 40px !important;
	text-align: center !important;
	font-size: 1.2em !important;
	line-height: 2em !important;	
	background: var(--icon-background) !important;
	border-radius: 50% !important;
	transition: 0.5s !important;
}

.home-social-icons::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background: var(--blue);
	transition: 0.5s;
	transform: scale(0.9);
	z-index: -1;
}

.home-social-icons:hover::before {
	transform: scale(1.1);
	box-shadow: 0 0 15px var(--blue-dark);
}

.home-social-icons:hover {
	color: var(--blue);
	box-shadow: 0 0 5px var(--blue);
	text-shadow: 0 0 2px var(--blue);
}

.social-icons {
	display: inline-block !important;
	padding-right: 15px;
	padding-left: 15px;
}

@media (max-width: 480px) {
	.social-icons {
		padding-right: 3px;
		padding-left: 3px;
	}
}

.icon-colour {	
	color: var(--icon-color) !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
	/* background-color: rgb(10, 4, 22); */
	background-color: var(--grey);
	bottom: 0 !important;
	padding-top: 10px !important;
	padding-bottom: 8px !important;
}

.footer-copywright {
	text-align: center !important;
}

.footer-body {
	text-align: center !important;
}

@media (max-width: 767px) {
	.footer-copywright {
		text-align: center !important;
	}

	.footer-body {
		text-align: center !important;
	}
}

@media (max-width: 992px) {
	.footer {
		/* display: none !important; */
		visibility: hidden !important;
	}
}

.footer h3 {
	font-size: 1em;
	color: var(--white) !important;
	margin-top: 0.5em !important;
	margin-bottom: 0.5em !important;
}

.footer-icons {
	margin-top: 0.5em !important;
	margin-bottom: 0.5em !important;
	padding: 0 !important;
}

/* --------- */
/* Projects */
/* --------- */
.project-section {
	position: relative !important;
	padding-top: 110px !important;
	padding-bottom: 10px !important;
	background-image: var(--section-background-color) !important;
	min-height: 95vh;
	background-color: var(--background) !important;
}

@media (max-width: 992px) {
	.project-section {
		padding-top: 50px !important;
	}
}

.project-card {
	padding-top: 30px !important;
	padding-bottom: 50px !important;
	padding-left: 25px !important;
	padding-right: 25px !important;
	height: auto !important;	
}

.project-card-view {
	box-shadow: 0 4px 5px 3px rgba(53, 79, 136, 0.459) !important;
	color: var(--blue-dark) !important;
	background-color: transparent !important;
	opacity: 0.9 !important;
	transition: all 0.5s ease 0s !important;
	height: 100% !important;	
	background-color: var(--background) !important;
}

.project-card-view:hover {
	transform: scale(1.02) !important;
	overflow: hidden !important;
	box-shadow: 0 4px 4px 5px rgba(72, 83, 144, 0.561) !important;
	opacity: 1 !important;
}

.card-title {
	color: var(--blue) !important;
}

.card-img-top {
	padding: 20px !important;
	opacity: 0.8 !important;
	border-radius: 10px !important;
}

.blog-img {
	padding: 0px !important;
	opacity: 0.8 !important;
	border-radius: 0px !important;
}

.nav-pills .nav-link {
	background: 0 0;
	border: 0;
	border-radius: 0.25rem;
	padding: 0.4rem 1rem !important;
}

.btn:focus {
	outline: none !important;
	box-shadow: none !important;
}

.project-heading {
	color: var(--blue) !important;
	font-size: 2em !important;
	font-weight: 500 !important;
	padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
	position: relative !important;
	padding-top: 80px !important;
	padding-bottom: 20px !important;
	background-image: var(--section-background-color) !important;
	color: var(--blue) !important;
	background-color: var(--background) !important;
}

@media (max-width: 992px) {
	.about-section {
		padding-top: 20px !important;
	}
}

.tech-icons {
	font-size: 3.5em !important;
	margin: 10px !important;
	padding: 10px !important;
	opacity: 0.93 !important;
	/* border: 1.7px solid rgba(200, 137, 230, 0.637) !important; */
	border: 1.1px solid var(--blue) !important;
	vertical-align: middle !important;
	text-align: center !important;
	border-radius: 5px !important;
	display: table !important;
	box-shadow: 4px 5px 4px 3px rgba(20, 4, 168, 0.137) !important;
	overflow: hidden !important;
	transition: all 0.4s ease 0s !important;
	color: var(--blue-dark) !important;
	background-color: var(--icon-background);
}

@media (max-width: 767px) {
	.tech-icons {
		margin: 10px !important;
	}
}

.tech-icons:hover {
	transform: scale(1.05) !important;
	overflow: hidden !important;
	/* border: 2.2px solid rgba(20, 4, 168, 0.883) !important; */
	border: 2.2px solid #274c77e1 !important;
}

.tech-icon-images {
	padding: 20px !important;
	line-height: 1.6 !important;
}

.react-github-calendar svg text {
	fill: var(--grey) !important;
	font-size: 30px;
	font-weight: bold;
}

.quote-card-view {
	border: none !important;
	color: var(--blue-dark) !important;
	background-color: transparent !important;
}

.about-activity {
	list-style: none !important;
	text-align: left !important;
	padding-left: 1px !important;
}

@media (max-width: 767px) {
	.about-img {
		padding-top: 0 !important;
	}
}

/* --------- */
/* Resume */
/* --------- */

.resume-section {
	position: relative !important;
	padding-top: 110px !important;
	padding-bottom: 30px !important;
	background-image: var(--section-background-color) !important;
	color: var(--blue) !important;
	min-height: 95vh;
	background-color: var(--background) !important;
}

@media (max-width: 992px) {
	.resume-section {
		padding-top: 50px !important;
	}
}

.resume {
	padding-top: 50px;
	padding-bottom: 50px;
	justify-content: center;
}

.resume-left {
	padding-right: 80px !important;
}

.resume-right {
	padding-left: 80px !important;
}

@media (max-width: 767px) {
	.resume-left {
		padding-right: 15px !important;
		padding-left: 15px !important;
	}

	.resume-right {
		padding-right: 15px !important;
		padding-left: 15px !important;
	}
}

.resume .resume-title {
	font-size: 2em;
	font-weight: 700;
	padding-top: 30px;
	padding-bottom: 30px;
}

.resume .resume-item {
	padding: 0 0 10px 25px;
	margin-top: -2px;
	border-left: 2px solid #8a49a8;
	position: relative;
}

.resume .resume-item .resume-title {
	line-height: 18px;
	font-size: 0.9em;
	background: #5234795d;
	padding: 8px 15px;
	display: inline-block;
	font-weight: 600;
	margin-bottom: 10px;
}

.resume .resume-item ul {
	padding-left: 20px;
	text-align: justify;
}

.resume .resume-item ul li {
	padding-bottom: 10px;
	list-style: none;
}

.resume .resume-item:last-child {
	padding-bottom: 0;
}

.resume .resume-item::before {
	content: "";
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50px;
	left: -9px;
	top: 0;
	background: #fff;
	border: 2px solid #8a49a8;
}

.react-pdf__Page__canvas {
	margin: 0 auto;
	width: 90% !important;
	height: 100% !important; 
  }

  .float{
	position:fixed;
	/* width:60px;
	height:60px; */
	top:10px;
	right:40px;	
	z-index:9999;	
}

  .toggle-checkbox {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
	z-index:9999;
  }
  
  .toggle-slot {
	position: relative;	
	z-index:9999;
  }
  
  .toggle-checkbox:checked ~ .toggle-slot .toggle-button {	
	transform: translate(0.1em, 0);
  }
  
  .sun-icon {
	position: absolute;
	height: 2em;
	width: 2em;
	color: #fdfdfd;
    filter: drop-shadow(1px 3px 5px rgb(234, 235, 223));
	cursor: pointer;
  }
  
  .sun-icon-wrapper {
	position: absolute;	
	opacity: 1;	
	transform: translate(-0.5em, 0.2em) rotate(15deg);
	transform-origin: 50% 50%;
	transition: opacity 150ms, transform 500ms cubic-bezier(.26,2,.46,.71);
  }
  
  .toggle-checkbox:checked ~ .toggle-slot .sun-icon-wrapper {
	opacity: 0;	
	transform: translate(3em, -3em) rotate(178deg)
  }
  
  .moon-icon {
	position: absolute;
	height: 1.6em;
	width: 1.6em;
	color: white;
	filter: drop-shadow(1px 0px 3px rgb(255, 255, 255));
	cursor: pointer;
  }
  
  .moon-icon-wrapper {
	position: absolute;	
	opacity: 0;	
	transform: translate(0em, 4em) rotate(0deg);
	transform-origin: 50% 50%;
	transition: opacity 150ms, transform 500ms cubic-bezier(.26,2.5,.46,.71);
  }
  
  .toggle-checkbox:checked ~ .toggle-slot .moon-icon-wrapper {
	opacity: 1;	
	transform: translate(0em, 0em) rotate(50deg);
  }

  svg {
    stroke: transparent;
    stroke-width: 0px;
}